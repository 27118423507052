#root,
.primary-btn {
  position: relative;
}
.primary-btn,
body {
  text-align: center;
}
.close-btn,
.menu {
  top: 50%;
  cursor: pointer;
}
.blog-card .card,
.close-btn,
.faq-tab-click span,
.header-section .logo,
.home-blog-section .blog-right-carousel .card,
.list-group-item,
.menu {
  cursor: pointer;
}
body {
  font-family: Poppins, sans-serif !important;
  font-weight: inherit;
  font-size: 1rem;
  line-height: var(--ast-body-line-height, 1.65em);
}
.banner-heading {
  color: var(--e-global-color-astglobalcolor8);
  font-size: 52px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2em;
  letter-spacing: 1px;
}
.title-heading {
  font-size: 28px;
}
h2 {
  margin-bottom: 15px !important;
}
.benifical-text-left p,
.blog-card .card .card-body p,
.card-text,
.footer-section a,
.footer-section p,
.header-section .navbar a,
.text-size-14 {
  font-size: 14px;
}
.mt-8 {
  margin-top: 80px;
}
.about-page .what-we-do,
.abt-our-journey,
.blogs-page .blog-posts,
.expand-earning,
.home-blog-section,
.home-partner-section,
.jobs-page .logistic-team,
.py-100,
.services-page .our-capabilities,
.services-page .our-services {
  padding: 100px 0;
}
.py-50 {
  padding: 50px 0;
}
.auth-outer .input-field label,
.cards-title-heading {
  font-size: 16px;
}
.blog-detail-page .blog-detail h1,
.col-md-12 + .col-md-12,
.faq-tab + .faq-tab,
.form-row + .form-row {
  margin-top: 20px;
}
.btn-lg {
  width: 190px !important;
}
.btn-lg svg rect {
  width: 185px !important;
}
.btn-lg:hover svg rect {
  width: 170px !important;
}
.blogs-page .card-body h5,
.home-blog-section .card-body h5,
.home-blog-section .card-body p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
b,
p b,
strong {
  font-weight: 600 !important;
}
.primary-btn {
  display: inline-block;
  font-size: 12px;
  color: #000;
  overflow: hidden;
  padding: 11px 35px;
  border: 1px solid grey !important;
  background-color: transparent;
  transition: 0.2s ease-in;
}
.file-button,
ul {
  padding: 0 !important;
}
.primary-btn:hover,
.submit-btn {
  border-color: #f5212e !important;
}
.blog-detail-page span.tags-ti,
.primary-btn svg {
  position: absolute;
  top: 0;
  left: 0;
}
.primary-btn svg rect {
  fill: none;
  stroke: #7a7a7a;
  stroke-width: 1;
  stroke-dasharray: 422, 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1.2s;
  -moz-transition-property: all;
  -moz-transition-duration: 1.2s;
  -moz-transition-timing-function: cubic-bezier(100, 50, 21, 6);
  @include transition(all 1300ms $ease-out-expo);
}
.primary-btn:hover svg rect {
  stroke: #f5212e;
  stroke-width: 5;
  stroke-dasharray: 10, 310;
  stroke-dashoffset: 33;
}
.primary-btn:hover {
  color: #fff;
  background-color: #f5212e !important;
  font-weight: 700;
}
.breadcrumb-item.active,
.secondry--btn-color {
  color: #fff !important;
}
#wrapper .breadcrumb-item.active {
  color: #000 !important;
}
.secondry--btn-color:hover svg rect {
  stroke: #ffffff !important;
}
.submit-btn {
  background-color: #f5212e;
  color: #fff;
  transition: 1s ease-in-out;
  border-radius: 5px;
  font-size: 16px;
}
.submit-btn:hover {
  background-color: transparent;
}
.close-btn {
  background: 0 0;
  border: 0;
  color: transparent;
  letter-spacing: 1.5px;
  outline: 0;
  overflow: hidden;
  text-transform: uppercase;
  transform: translate(0, 0);
  transition: 0.2s ease-in;
}
.close-btn::after,
.close-btn::before {
  background-color: #f5212e;
  content: "";
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  transform-origin: center left;
  transition: 0.3s ease-in;
  width: 141.4214px;
  z-index: -1;
}
.close-btn::before {
  top: 0;
  transform: rotate(45deg);
}
.close-btn::after {
  bottom: 0;
  transform: rotate(-45deg);
}
.close-btn:hover,
.footer-section a {
  color: #fff;
}
.close-btn:hover::after,
.close-btn:hover::before {
  height: 50px;
  transform: rotate(0);
}
.file-button {
  display: flex !important;
  width: 130px !important;
  text-align: center !important;
  background-color: #b0b0b0 !important;
  font-size: 10px !important;
  height: 49px;
  box-shadow: none !important;
}
input:focus {
  outline: 0 !important;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none !important;
}
.validation-error {
  text-align: left;
  color: #f5212e;
  font-size: 12px;
  border-radius: 3px;
}
.custom-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Mui-selected {
  background-color: #f5212e !important;
}
.header-section {
  position: absolute;
  z-index: 1;
}
.header-main {
  background-color: #fff;
  border-radius: 50px;
  padding: 10px;
}
.blog-detail-page .sidebar a,
.category-detail-page .sidebar a,
.header-section .offcanvas-body a {
  color: #000;
}
.header-section .offcanvas-body ul li {
  margin-bottom: 24px;
}
.header-section .offcanvas-body .carrier-menu-sub-list {
  list-style-type: disc;
}
.header-section .offcanvas-body .carrier-menu-sub-list li::marker {
  color: #d9d9d9;
}
.header-section .offcanvas-body .title-heading {
  font-size: 22px;
  font-weight: 500;
}
.blog-card .card .card-body h5,
.header-section .offcanvas-body .left-side-bottom-text p,
.services-cards-col .card .card-title {
  font-size: 18px;
}
.header-section .navbar a,
.header-section .side-menu-list a {
  background-image: linear-gradient(to right, #f5212e, #f5212e 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 0.5s ease-in-out;
}
.header-section .navbar a:before,
.header-section .side-menu-list a::before {
  content: "";
  background: #f5212e;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: 0.3s ease-in-out;
}
.header-section .navbar a:hover,
.header-section .side-menu-list a:hover {
  background-position: 0;
}
.abt-our-journey .abt-pg-right-img img,
.auth-outer .input-field,
.header-section .navbar a:hover::before,
.header-section .side-menu-list a:hover::before,
.home-tacking-section .home-tracking-form input,
.partner-left img,
.wht-we-do-left-img img {
  width: 100%;
}
.menu {
  display: inline-block;
  position: absolute;
  width: 76px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #f5212e;
}
.menu::after,
.menu::before {
  position: absolute;
  transition: 0.4s;
  opacity: 0;
  pointer-events: none;
}
.menu:before {
  content: "M \00a0 \00a0 \00a0 \00a0 \00a0U";
  top: -8px !important;
  left: 15%;
  width: 470px !important;
}
.menu:after {
  content: "N";
  top: -8px !important;
  left: 62%;
}
.menu:hover::after,
.menu:hover::before {
  top: -4.3px;
  opacity: 1;
  transition: 0.2s, opacity 0.17s ease-in 30ms;
}
.bar {
  width: 20%;
  height: 2px;
  background: #f5212e;
  margin: 0 auto 4px;
  transition: transform 0.4s;
}
.header-section .offcanvas:before {
  content: "";
  background-image: url("./assets/home/usravens-side-logo.png");
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-repeat: no-repeat;
  right: 0;
  background-size: contain;
  background-position: right;
}
.home-banner {
  background-color: #f3f3f3;
  padding-top: 138px;
  padding-bottom: 65px;
}
.banne-text {
  padding-top: 104px;
}
.video-container {
  border-style: none;
  border-radius: 90px 162px;
  height: min-content;
  background: linear-gradient(
    270deg,
    #fff 0,
    #d3cbcb 20%,
    #9e7477 49%,
    #cb7278 73%,
    #c26f74 91%,
    #c6646a 100%
  );
  overflow: hidden;
  -webkit-transform: rotate(5deg) skew(5deg);
  -ms-transform: rotate(5deg) skew(5deg);
  -moz-transform: rotate(5deg) skew(5deg);
  -o-transform: rotate(5deg) skew(5deg);
  transform: rotate(5deg) skew(5deg);
  box-shadow: #f3c9cb 0 0 0, #f3c9cb 1px -1px 0, #f3c9cb 2px -2px 0,
    #f3c9cb 3px -3px 0, #f3c9cb 4px -4px 0, #f3c9cb 5px -5px 0,
    #f3c9cb 6px -6px 0, #f3c9cb 7px -7px 0, #f3c9cb 8px -8px 0,
    #f3c9cb 9px -9px 0, #f3c9cb 10px -10px 0, #f3c9cb 11px -11px 0,
    #f3c9cb 12px -12px 0, #f3c9cb 13px -13px 0, #f3c9cb 14px -14px 0,
    #f3c9cb 15px -15px 0, #f3c9cb 16px -16px 0, #f3c9cb 17px -17px 0,
    #f3c9cb 18px -18px 0, #f3c9cb 19px -19px 0, #f3c9cb 20px -20px 0,
    #f3c9cb 21px -21px 0, #f3c9cb 22px -22px 0, #f3c9cb 23px -23px 0,
    #f3c9cb 24px -24px 0, #f3c9cb 25px -25px 0, #f3c9cb 26px -26px 0,
    #f3c9cb 27px -27px 0, #f3c9cb 28px -28px 0, #f3c9cb 29px -29px 0,
    #f3c9cb 30px -30px 0, #f3c9cb 31px -31px 0, #f3c9cb 32px -32px 0,
    #f3c9cb 33px -33px 0, #f3c9cb 34px -34px 0, #f3c9cb 35px -35px 0,
    #f3c9cb 36px -36px 0, #f3c9cb 37px -37px 0, #f3c9cb 38px -38px 0,
    #f3c9cb 39px -39px 0, #f3c9cb 40px -40px 0, #f3c9cb 41px -41px 0,
    #f3c9cb 42px -42px 0, #f3c9cb 43px -43px 0, #f3c9cb 44px -44px 0,
    #f3c9cb 45px -45px 0, #f3c9cb 46px -46px 0, #f3c9cb 47px -47px 0,
    #f3c9cb 48px -48px 0, #f3c9cb 49px -49px 0, #f3c9cb 50px -50px 0,
    #f3c9cb 51px -51px 0, #f3c9cb 52px -52px 0, #f3c9cb 53px -53px 0,
    #f3c9cb 54px -54px 0, #f3c9cb 55px -55px 0, #f3c9cb 56px -56px 0,
    #f3c9cb 57px -57px 0, #f3c9cb 58px -58px 0, #f3c9cb 59px -59px 0,
    #f3c9cb 60px -60px 0, #f3c9cb 61px -61px 0, #f3c9cb 62px -62px 0,
    #f3c9cb 63px -63px 0, #f3c9cb 64px -64px 0, #f3c9cb 65px -65px 0,
    #f3c9cb 66px -66px 0, #f3c9cb 67px -67px 0, #f3c9cb 68px -68px 0,
    #f3c9cb 69px -69px 0, #f3c9cb 70px -70px 0, #f3c9cb 71px -71px 0,
    #f3c9cb 72px -72px 0, #f3c9cb 73px -73px 0, #f3c9cb 74px -74px 0,
    #f3c9cb 75px -75px 0, #f3c9cb 76px -76px 0, #f3c9cb 77px -77px 0,
    #f3c9cb 78px -78px 0, #f3c9cb 79px -79px 0, #f3c9cb 80px -80px 0,
    #f3c9cb 81px -81px 0, #f3c9cb 82px -82px 0, #f3c9cb 83px -83px 0,
    #f3c9cb 84px -84px 0, #f3c9cb 85px -85px 0, #f3c9cb 86px -86px 0,
    #f3c9cb 87px -87px 0, #f3c9cb 88px -88px 0, #f3c9cb 89px -89px 0,
    #f3c9cb 90px -90px 0, #f3c9cb 91px -91px 0, #f3c9cb 92px -92px 0,
    #f3c9cb 93px -93px 0, #f3c9cb 94px -94px 0, #f3c9cb 95px -95px 0,
    #f3c9cb 96px -96px 0, #f3c9cb 97px -97px 0, #f3c9cb 98px -98px 0,
    #f3c9cb 99px -99px 0, #f3c9cb 100px -100px 0, #f3c9cb 101px -101px 0,
    #f3c9cb 102px -102px 0, #f3c9cb 103px -103px 0, #f3c9cb 104px -104px 0,
    #f3c9cb 105px -105px 0, #f3c9cb 106px -106px 0, #f3c9cb 107px -107px 0,
    #f3c9cb 108px -108px 0, #f3c9cb 109px -109px 0, #f3c9cb 110px -110px 0,
    #f3c9cb 111px -111px 0, #f3c9cb 112px -112px 0, #f3c9cb 113px -113px 0,
    #f3c9cb 114px -114px 0, #f3c9cb 115px -115px 0, #f3c9cb 116px -116px 0,
    #f3c9cb 117px -117px 0, #f3c9cb 118px -118px 0, #f3c9cb 119px -119px 0,
    #f3c9cb 120px -120px 0, #f3c9cb 121px -121px 0, #f3c9cb 122px -122px 0,
    #f3c9cb 123px -123px 0, #f3c9cb 124px -124px 0, #f3c9cb 125px -125px 0,
    #f3c9cb 126px -126px 0, #f3c9cb 127px -127px 0, #f3c9cb 128px -128px 0,
    #f3c9cb 129px -129px 0, #f3c9cb 130px -130px 0, #f3c9cb 131px -131px 0,
    #f3c9cb 132px -132px 0, #f3c9cb 133px -133px 0, #f3c9cb 134px -134px 0,
    #f3c9cb 135px -135px 0, #f3c9cb 136px -136px 0, #f3c9cb 137px -137px 0,
    #f3c9cb 138px -138px 0, #f3c9cb 139px -139px 0, #f3c9cb 140px -140px 0,
    #f3c9cb 141px -141px 0, #f3c9cb 142px -142px 0, #f3c9cb 143px -143px 0,
    #f3c9cb 144px -144px 0, #f3c9cb 145px -145px 0, #f3c9cb 146px -146px 0,
    #f3c9cb 147px -147px 0, #f3c9cb 148px -148px 0, #f3c9cb 149px -149px 0,
    #f3c9cb 150px -150px 0, #f3c9cb 151px -151px 0, #f3c9cb 152px -152px 0,
    #f3c9cb 153px -153px 0, #f3c9cb 154px -154px 0, #f3c9cb 155px -155px 0,
    #f3c9cb 156px -156px 0, #f3c9cb 157px -157px 0, #f3c9cb 158px -158px 0,
    #f3c9cb 159px -159px 0, #f3c9cb 160px -160px 0, #f3c9cb 161px -161px 0,
    #f3c9cb 162px -162px 0, #f3c9cb 163px -163px 0, #f3c9cb 164px -164px 0,
    #f3c9cb 165px -165px 0, #f3c9cb 166px -166px 0, #f3c9cb 167px -167px 0,
    #f3c9cb 168px -168px 0, #f3c9cb 169px -169px 0, #f3c9cb 170px -170px 0,
    #f3c9cb 171px -171px 0, #f3c9cb 172px -172px 0, #f3c9cb 173px -173px 0,
    #f3c9cb 174px -174px 0, #f3c9cb 175px -175px 0, #f3c9cb 176px -176px 0,
    #f3c9cb 177px -177px 0, #f3c9cb 178px -178px 0, #f3c9cb 179px -179px 0,
    #f3c9cb 180px -180px 0, #f3c9cb 181px -181px 0, #f3c9cb 182px -182px 0,
    #f3c9cb 183px -183px 0, #f3c9cb 184px -184px 0, #f3c9cb 185px -185px 0,
    #f3c9cb 186px -186px 0, #f3c9cb 187px -187px 0, #f3c9cb 188px -188px 0,
    #f3c9cb 189px -189px 0;
}
.video-container .video {
  display: block;
  border-radius: 90px 162px;
  border: 11px solid transparent;
}
.banner-dot-img {
  position: absolute;
  top: 340px;
  left: 42%;
}
.banner-phone-svg,
.services-page .our-services .duble-check-icon {
  filter: brightness(0);
}
.home-about-section {
  padding-top: 120px;
  padding-bottom: 170px;
}
.abt-image {
  border: 1px solid #d9dcdc;
  border-radius: 50px;
  height: fit-content;
}
.abt-image img {
  border-radius: 50px 0 50px 50px;
  transform: translate(-30px, -30px);
}
.abt-dot-img {
  position: absolute;
  top: 630px;
}
.home-services-section {
  background-color: #353a3e;
  background-image: url("./assets/home/tire.webp");
  background-repeat: no-repeat;
}
.services-cards-col .card img {
  margin: 0 !important;
  border-radius: 0 15px 15px;
}
.services-cards-col .card .card-body {
  padding-bottom: 0;
}
.services-cards-col .card {
  box-shadow: rgba(203, 203, 204, 0.4) 5px 5px,
    rgba(203, 203, 204, 0.3) 10px 10px, rgba(203, 203, 204, 0.2) 15px 15px,
    rgba(203, 203, 204, 0.1) 20px 20px, rgba(203, 203, 204, 0.05) 25px 25px;
}
.about-page .our-business,
.faq,
.home-benificial-section {
  background-color: #f8f8f8;
  padding: 100px 0;
}
.benifical-text-right img {
  padding: 5px;
  background-color: #f5212e;
  border-radius: 0 15px 15px;
  margin-right: 15px;
  transition: 1s ease-in-out;
}
.benifical-text-right span:hover img,
.footer-newsletter button:hover {
  background-color: #b71620;
}
.benifical-text-left,
.capabilities-right {
  text-align: right;
}
.home-partner-section .countup-outer .counter {
  font-size: 28px;
  color: #f4212e;
  font-weight: 600;
}
.prt-dot-img {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1 !important;
}
.home-tacking-section {
  padding-bottom: 100px;
}
.tracking-bg-img {
  padding: 40px 0;
  background-color: #343a3d;
  background-image: url(./assets/home/track_i.png);
  background-position: center left;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 15px;
}
.footer-section,
.title-banner {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.home-tacking-section .home-tracking-form div {
  border: none;
  width: 100%;
  box-shadow: none !important;
}
.home-tracking-form form {
  background-color: #ffff;
  padding: 10px;
  border-radius: 15px;
}
.home-tracking-form form .tracking-btn {
  border-radius: 10px;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 18px;
  padding: 5px 35px;
  background-color: #f5212e;
  border: none;
  color: #fff;
  transition: 0.3s ease-in;
}
.home-tracking-form form .tracking-btn:hover,
.open-tab .faq-tab-click span:after,
.open-tab .faq-tab-click span:before {
  background-color: #000;
}
.tracking-results-img {
  position: absolute;
  top: -70px;
  right: -125px;
}
.tracking-results-img img {
  width: 300px;
}
.home-feedback-section {
  background-color: #f8f8f8;
  padding: 60px 0 0;
  align-items: center;
}
.carousel-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 50px;
}
.carousel-item-padding {
  padding-bottom: 120px;
}
.fb-card-outer {
  background-color: #ffff;
  width: 95%;
  padding: 35px 15px 40px;
  border-radius: 15px;
  position: relative;
  transition: 1s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.fb-card-outer.selected {
  background-color: #f5212e;
  color: #ffff;
  margin-top: -30px;
}
.fb-card-outer.selected .quoteImg {
  filter: brightness(100);
}
.fb-card-img-center {
  display: flex;
  justify-content: center;
}
.fb-card-img-center img {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  object-fit: cover;
}
.fb-card-img {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: -48px;
}
.home-blog-section .carousel-container {
  padding-top: 0 !important;
}
.blog-carousel-item {
  margin-right: 10px;
  width: 326px !important;
}
.list-heading {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}
.footer-section {
  background-image: url("./assets//home/footer-bg.jpeg");
  padding: 50px 0 0;
  z-index: 2;
  position: relative;
}
.footer-bar {
  padding: 16px 0;
}
.footer-logo a img {
  width: 150px;
}
.footer-contact {
  border-right: 1px solid #f5212e;
  padding-right: 100px;
}
.footer-contact a {
  text-decoration: none;
  color: #000;
}
.about-page .logistic-card:hover h1,
.blog-detail-page .blog-detail a,
.blog-detail-page .sidebar a:hover,
.category-detail-page .sidebar a:hover,
.footer-contact a:hover,
.open-tab .faq-tab-click,
.partner-page p a {
  color: #f5212e;
}
.footer-contact a:hover svg {
  fill: #f5212e;
}
.footer-social-links ul {
  list-style-type: none;
  margin: 0 0 0 58px;
  padding: 0;
}
.footer-social-links ul li {
  border: 1px solid #f5212e;
  border-radius: 50%;
  padding: 8px;
  margin-right: 6px;
}
.footer-social-links ul li img {
  filter: brightness(0);
  width: 1em;
  height: 1em;
  position: relative;
  display: block;
}
.footer-social-links ul li:hover {
  background-color: #f5212e;
  transition: 1s;
}
.footer-social-links ul li:hover img {
  filter: brightness(1);
  transition: 1s;
  transform: rotateY(360deg);
}
.footer-bottom ul li {
  margin-top: 10px;
  font-size: 13px;
}
.footer-bottom ul li a {
  width: fit-content;
}
.footer-bottom ul li a img {
  transition: 0.5s;
}
.footer-bottom ul li a:hover img {
  margin-right: 10px;
}
.footer-newsletter input {
  padding: 9px;
  border-radius: 10px 0 0 10px;
  border: none;
  width: 100%;
}
.footer-newsletter input::placeholder {
  font-size: 13px !important;
  color: #7b7b7b;
}
.footer-newsletter button {
  border: none;
  border-radius: 0 10px 10px 0 !important;
  background-color: #f5212e;
  color: #ffff;
  font-size: 12px;
  width: 100%;
  transition: 1s;
  max-width: 100px;
}
.footer-copyright-section {
  background-color: #f5212e;
  padding: 10px 0;
}
.sidebar-social-section {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 1;
}
.sidebar-social-section ul li {
  background-color: #f5212e;
  padding: 5px;
  margin-top: 2px;
  border-radius: 10px 0 0 10px;
}
.sidebar-social-section ul li:hover img {
  transition: 1s ease-in-out;
  transform: rotateY(360deg);
}
.sidebar-social-section ul li:hover {
  transition: 1s ease-in-out;
  margin-left: -20px;
  cursor: pointer;
}
.title-banner {
  position: relative;
  padding: 100px;
  z-index: 0;
}
.title-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: -1;
}
.about-page .logistics-solutions,
.jobs-page .join-us {
  background-position: center center;
  background-size: cover;
  z-index: 0;
  background-repeat: no-repeat;
}
.title-banner-main {
  position: relative;
  color: #fff;
  text-align: center;
  margin: 100px 0;
  z-index: 1;
}
.title-banner-main nav {
  justify-content: center;
  display: flex;
  align-items: center;
}
.title-banner-main nav ol {
  border-radius: 5px;
  background: #ffffff1c;
  padding: 4px 28px;
}
.breadcrumb-item a {
  color: gray;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: "-" !important;
  color: #fff !important;
}
.abt-our-journey .abt-pg-right-img .years-count {
  position: absolute;
  top: 30px;
  left: 7%;
  background-color: #f5212e;
  color: #fff;
  padding: 0 15px;
  display: flex;
  width: 190px;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
}
.abt-pg-right-img .years-count .counter {
  font-size: 48px;
  font-weight: 700;
}
.abt-pg-right-img .years-count .years-count-text {
  margin-right: 12px;
}
.about-page .logistics-solutions {
  position: relative;
  background-image: url("./assets/aboutUs/logistics-bg.jpg");
  padding: 100px 0;
}
.about-page .logistics-solutions::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: -1;
}
.about-page .logistics-cards-outer {
  display: flex;
  justify-content: center;
  margin: 50px 0 0;
  gap: 20px;
}
.about-page .logistic-card {
  background-color: #fff;
  padding: 40px 20px 44px;
  text-align: center;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: calc(33.333% - 20px);
  border-radius: 15px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    transform var(--e-transform-transition-duration, 0.4s);
}
.about-page .logistics-solutions .card-icon,
.about-page .what-we-do-list-item .list-icon {
  padding: 35px;
  justify-content: center;
  width: 50px;
  display: flex;
  height: 50px;
  margin: 0 auto 20px;
}
.about-page .logistic-card:hover {
  webkit-transform: scale(1.043333);
  transform: scale(1.043333);
}
.about-page .logistic-card:hover .card-icon {
  background-color: #f5212e;
}
.about-page .logistic-card:hover .card-icon img {
  filter: brightness(1000);
}
.about-page .logistics-solutions .card-icon {
  border: 1px solid #f5212e;
  align-items: center;
  border-radius: 50%;
  transition: 1s;
}
.about-page .logistics-solutions .card-icon img,
.capabilities-outer .capabilities-list-icon img {
  filter: brightness(0);
  transition: 1s;
}
.about-page .what-we-do-list-item {
  display: flex;
  justify-content: space-between;
}
.about-page .what-we-do-list-item .list-icon {
  background-color: #f5212e;
  align-items: center;
  border-radius: 0 15px 15px;
}
.services-page .our-services .nav-item .nav-link {
  padding: 10px 32px;
  border-radius: 50px;
  background-color: #f9f9fb;
  color: #000;
  transition: 0.5s ease-in-out;
}
.services-page .our-services .nav-item .nav-link.active,
.services-page .our-services .nav-item .nav-link:hover {
  background-color: #f5212e !important;
  color: #fff;
}
.services-page .our-services .nav .nav-item {
  margin-top: 20px;
  margin-right: 10px;
}
.services-page .our-services .tab-pane {
  transition: opacity 0.4s ease-in !important;
}
.services-counts {
  background-color: #f8f8f8;
  padding: 80px 0;
}
.services-count-outer {
  width: 32%;
  background-color: #f5212e;
  text-align: center;
  padding: 20px 5px 14px;
  border-radius: 25px;
  font-size: 19px;
  color: #fff;
}
.services-count-outer .counter {
  font-size: 40px;
  font-weight: 600;
}
.capabilities-outer:hover .capabilities-list-icon {
  background-color: #f5212e;
  transition: 0.6s ease-in-out;
}
.capabilities-outer:hover .capabilities-list-icon img {
  filter: brightness(1);
}
.capabilities-outer .capabilities-list-text p b {
  font-weight: 600;
}
span.capabilities-list-text p {
  margin-bottom: 4px;
}
.capabilities-list-icon {
  background-color: #d9d9d9;
  padding: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 15px 15px;
  width: 50px;
  height: 50px;
  margin: 0 auto 20px;
}
.faq-tab,
.jobs-page .Join-form-outer,
.jobs-page .join-form,
.payment-contact,
.payment-image-container {
  background-color: #fff;
}
.capabilities-right .capabilities-list-icon {
  border-radius: 15px 0 15px 15px;
}
.faq-tab-click {
  font-size: 16px;
  font-weight: 500;
  padding: 18px 40px 18px 20px;
  position: relative;
  transition: 0.6s;
  margin: auto;
}
.faq-tab-content {
  border-radius: 0 0 2px 2px;
  height: 0;
  overflow: hidden;
  transition: 0.5s;
  max-height: 0;
}
.faq-tab-content p {
  font-size: 14px;
  line-height: 24px;
  padding: 0 20px 5px;
}
.open-tab .faq-tab-content {
  max-height: 100vh;
  height: 100%;
}
.faq-tab-click span {
  position: absolute;
  width: 40px;
  height: 100%;
  right: 0;
  z-index: 2;
  top: 0;
  display: block;
}
.faq-tab-click span:after,
.faq-tab-click span:before {
  content: "";
  position: absolute;
  background-color: #000;
}
.faq-tab-click span:after {
  width: 2px;
  height: 16px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.faq-tab-click span:before {
  width: 16px;
  height: 2px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.blog-detail-page .entry-meta,
.open-tab .faq-tab-click span:after {
  display: none;
}
.jobs-page {
  background-color: #f8f8f8f8;
}
.jobs-page .logistic-team .logistic-team-inner {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.jobs-page .join-us {
  position: relative;
  background-image: url("./assets/jobs/logistic-form.jpg");
  padding: 100px 0 200px;
}
.jobs-page .join-us::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
}
.jobs-page .join-form {
  max-width: 1100px;
  margin: -101px auto 80px;
  width: 90%;
  /* z-index: 1999999999; */
  position: relative;
  padding: 70px 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  border-radius: 10px;
}
.jobs-page .join-form .checkbox-outer {
  width: 96.3%;
}
.auth-outer .form-row {
  display: flex;
  justify-content: space-around;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0 !important;
}
.auth-outer .input-file-field {
  width: 73%;
}
.auth-outer .input-field div,
.input-file-field div {
  padding: 14px;
}
form.auth-outer div:nth-child(3) .form-group.col-md-5.d-flex .input-field div {
  border-radius: 4px 0 0 4px;
}
.Mui-focused {
  color: #000 !important;
}
.Mui-focused fieldset {
  border-color: #000 !important;
}
.Mui-error {
  color: #d32f2f !important;
}
.Mui-error fieldset {
  border-color: #d32f2f !important;
}
.jobs-page .join-form p {
  font-size: 15px;
}
.jobs-page span.MuiCheckbox-colorPrimary {
  margin-right: 6px;
}
.jobs-page label.form-group.col-md-5 {
  margin-bottom: 6px;
  margin-top: 5px;
}
.jobs-page label.file-button {
  margin-left: 10px;
}
.blog-card .card {
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.blog-card .card .card-body {
  margin-bottom: 15px;
  min-height: 100%;
}
.blog-card .card .card-body .blog-date {
  font-size: 12px;
}
.blog-card .card img {
  transition: transform 0.3s;
}
.blog-card .card:hover img {
  transform: scale(1.1);
}
.blog-detail-page h3 {
  font-size: 22px;
  margin-top: 24px;
  margin-bottom: 8px;
}
.blog-detail-page h2 {
  font-size: 24px;
  margin-top: 26px;
  margin-bottom: 8px !important;
}
.blog-detail-page h4 {
  font-size: 20px;
  margin-top: 20px;
}
.blog-detail-page ol li h4{
  font-size: 16px;
  font-weight: 600;
}
.blog-detail-page,
.category-detail-page {
  padding: 150px 0 100px;
}
.list-group-item {
  border: none !important;
  background-color: #f8f8f8f8 !important;
  margin-top: 4px;
}
.blog-detail-page .col-md-8 ul {
  margin-top: 15px;
}
.blog-detail-page .col-md-8 ul li {
  background-image: url("./assets/home/check-double-line.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  padding-left: 30px;
}
.blog-detail-page span.mntgss {
  position: relative;
  display: inline-block;
  margin-top: 20px;
}
.blog-detail-page span .tags-links {
  display: inline-block;
  padding-left: 50px;
}
.blog-detail-page .tags-outer {
  flex-wrap: wrap;
}
.blog-detail-page .tags-outer a {
  background: #f0f0f0;
  padding: 6px 24px;
  margin: 3px;
  display: inline-block;
  color: #000;
  font-size: 14px;
  border-radius: 5px;
  text-transform: capitalize;
}
.blog-detail-page .sidebar .card-body {
  text-transform: capitalize;
}
.blog-detail-page table {
  border-collapse: separate;
  border-spacing: 0;
  margin: 0 0 1.5em;
  width: 100%;
}
.blog-detail-page tbody,
td,
tfoot,
th,
thead,
tr {
  border: 0 solid;
  border-color: inherit;
}
.blog-detail-page table,
.blog-detail-page td,
.blog-detail-page th {
  border: 1px solid #e5e5e5;
}
.blog-detail-page table th,
table td {
  text-align: left;
  padding: 8px;
  vertical-align: top;
}
.blog-detail-page p img.aligncenter {
  height: auto;
  display: block;
  width: 90px;
  margin-top: 15px;
  margin-bottom: 10px;
}
.blog-detail-page .avatar-96 {
  object-fit: cover;
  border-radius: 100px;
  object-position: top;
}
.blog-detail-page .author-info {
  background-color: #f8f9fa;
  padding: 18px 15px;
  border-radius: 8px;
}
.blog-detail-page .author-info .author-details p{
  font-size: 14px !important;
}
.stand-out {
  padding-bottom: 100px 0;
}
.partner-standout-list {
  margin-bottom: 100px;
}
.partners-card {
  background-color: #fff;
  padding: 40px 20px 44px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 15px;
  border: 1px solid #d6d6d6;
  transition: 0.8s;
  display: flex;
  flex-direction: column; /* Make it a column layout */
  justify-content: space-between; /* Space out elements */
  height: 100%; /* Ensures all cards take full height available */
}
.partners-card .card-icon {
  background-color: #f8f8f8;
  padding: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 0 auto 20px;
}
.partners-card .card-text {
  flex-grow: 1; /* Ensures the text area takes up remaining space */
  margin-bottom: 20px;
}
.partners-card:hover {
  background-color: #f5212e;
  color: #fff;
}
.not-found-text {
  position: absolute;
  left: 74%;
  top: 80%;
  transform: translate(-80%, -50%);
  width: 50%;
}
.carrier-main {
  position: relative;
  padding-bottom: 300px;
}
.become-carrier-page .rotate {
  transform: rotate(357deg);
  transform-origin: right top;
}
.become-carrier-page .carrier-text {
  background-color: #f8f8f8;
  position: absolute;
  top: 451px;
  left: 112px;
}
.become-carrier-page .carrier-text a {
  color: #f4212e;
}
#wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  height: 100vh;
  background-color: #f8f9fa;
}
#sidebar-wrapper {
  min-height: 100vh;
  width: 250px;
  margin-left: -250px;
  transition: 0.3s ease-out;
}
#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}
#page-content-wrapper {
  width: 100%;
  flex-grow: 1;
  overflow-x: hidden;
  background-color: #f8f9fa;
  padding: 20px;
}
.content-wrapper {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-height: calc(100vh - 160px) !important;
}
.sidebar-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}
.list-group-item.active {
  color: #f4212e !important;
}
.profileImg {
  max-width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100%;
}
.dashboard-home .card {
  background-color: #f8f9fa;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.dashboard-home .card-title {
  font-size: 1.2rem;
  font-weight: 700;
}
.dashboard-home .card-text.display-4 {
  font-size: 3.5rem;
  font-weight: 700;
  color: #f4212e;
}
.table.table.table-striped.mt-5 {
  overflow-x: auto;
  width: 100%;
  display: inline-block;
}
.detail-outer .profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.detail-outer .profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}
.detail-outer .feedback-text {
  font-size: 18px;
  line-height: 1.5;
  color: #333;
}
.detail-outer .feedback-text p {
  margin-bottom: 20px;
  text-align: center;
}
.preview-img {
  width: 100px !important;
}
.login-page-outer .input-field input#outlined-password-input {
  padding: 0;
}
.login-page-outer .input-field div:last-child div {
  padding: 0 6px;
}
.make-payments-outer {
  background-color: #fafafb;
}
.make-payments-outer .container {
  background-color: #f5f9ff;
}
.country-field {
  padding: 12px 0;
  width: 100%;
  border-color: #c4c4c4;
  color: #666;
  border-radius: 5px;
}
.tracking-card {
  padding: 30px 20px;
  background-color: #ffffffff;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
}
@media (max-width: 1400px) {
  .tracking-results-img {
    display: none;
  }
}
@media (min-width: 1250px) {
  .container {
    max-width: 1170px !important;
  }
  .home-banner {
    padding-bottom: 80px !important;
  }
}
@media (max-width: 1249px) {
  .container {
    max-width: 96% !important;
  }
}
@media (min-width: 992px) {
  .blogs-list .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (max-width: 991px) {
  body .mt-5 {
    margin-top: 30px !important;
  }
  body .mb-5 {
    margin-bottom: 30px !important;
  }
  .home-services-inner .services-right .services-cards-col {
    margin-top: 0;
  }
  .home-services-inner .services-right .services-cards-col p {
    display: -webkit-box;
    height: 5.2em;
    line-height: 1.3em;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .home-banner .title-heading {
    font-size: 3vw !important;
  }
  .navbar ul {
    margin-right: 0 !important;
  }
  .offcanvas-header .navbar ul {
    display: none !important;
  }
  .about-page .abt-our-journey .abt-page-left-text,
  .about-page .abt-our-journey .abt-pg-right-img,
  .about-page .what-we-do .what-we-do-right-text,
  .footer-bottom-text.col-md-3,
  .footer-newsletter input {
    width: 100%;
  }
  .footer-newsletter.col-md-4 {
    width: 40%;
  }
  .footer-bottom.row .col-md-2 {
    width: 30%;
  }
  .abt-image,
  .banner-dot-img,
  .home-title-br,
  .prt-dot-img,
  .sidebar-social-section,
  .tracking-results-img {
    display: none;
  }
  .home-about-section {
    padding: 100px 0;
  }
  .footer-bar .footer-social-links .social-links-list,
  .home-about-section .abt-text {
    margin: 0 !important;
  }
  .abt-dot-img img {
    width: 70px;
  }
  .about-page .abt-our-journey .abt-our-journey-inner,
  .about-page .what-we-do .what-we-do-inner,
  .home-services-section .home-services-inner {
    display: block !important;
  }
  .services-left {
    width: 100% !important;
  }
  .about-page .abt-page-left-text,
  .home-banner .banner-buttons button,
  .services-right {
    margin-top: 20px;
  }
  .footer-bar .footer-social-links {
    display: block !important;
    text-align: center;
  }
  .footer-contact {
    padding-right: 35px;
    padding-left: 99px;
  }
  .video-container {
    box-shadow: #f3c9cb 0 0 0, #f3c9cb 1px -1px 0, #f3c9cb 2px -2px 0,
      #f3c9cb 3px -3px 0, #f3c9cb 4px -4px 0, #f3c9cb 5px -5px 0,
      #f3c9cb 6px -6px 0, #f3c9cb 7px -7px 0, #f3c9cb 8px -8px 0,
      #f3c9cb 9px -9px 0, #f3c9cb 10px -10px 0, #f3c9cb 11px -11px 0,
      #f3c9cb 12px -12px 0, #f3c9cb 13px -13px 0, #f3c9cb 14px -14px 0,
      #f3c9cb 15px -15px 0, #f3c9cb 16px -16px 0, #f3c9cb 17px -17px 0,
      #f3c9cb 18px -18px 0, #f3c9cb 19px -19px 0, #f3c9cb 20px -20px 0,
      #f3c9cb 21px -21px 0, #f3c9cb 22px -22px 0, #f3c9cb 23px -23px 0,
      #f3c9cb 24px -24px 0, #f3c9cb 25px -25px 0, #f3c9cb 26px -26px 0,
      #f3c9cb 27px -27px 0, #f3c9cb 28px -28px 0, #f3c9cb 29px -29px 0,
      #f3c9cb 30px -30px 0, #f3c9cb 31px -31px 0, #f3c9cb 32px -32px 0,
      #f3c9cb 33px -33px 0, #f3c9cb 34px -34px 0, #f3c9cb 35px -35px 0,
      #f3c9cb 36px -36px 0, #f3c9cb 37px -37px 0, #f3c9cb 38px -38px 0,
      #f3c9cb 39px -39px 0, #f3c9cb 40px -40px 0, #f3c9cb 41px -41px 0,
      #f3c9cb 42px -42px 0, #f3c9cb 43px -43px 0, #f3c9cb 44px -44px 0,
      #f3c9cb 45px -45px 0, #f3c9cb 46px -46px 0, #f3c9cb 47px -47px 0,
      #f3c9cb 48px -48px 0, #f3c9cb 49px -49px 0, #f3c9cb 50px -50px 0,
      #f3c9cb 51px -51px 0, #f3c9cb 52px -52px 0, #f3c9cb 53px -53px 0,
      #f3c9cb 54px -54px 0, #f3c9cb 55px -55px 0, #f3c9cb 56px -56px 0,
      #f3c9cb 57px -57px 0, #f3c9cb 58px -58px 0, #f3c9cb 59px -59px 0,
      #f3c9cb 60px -60px 0, #f3c9cb 61px -61px 0, #f3c9cb 62px -62px 0,
      #f3c9cb 63px -63px 0, #f3c9cb 64px -64px 0, #f3c9cb 65px -65px 0,
      #f3c9cb 66px -66px 0, #f3c9cb 67px -67px 0, #f3c9cb 68px -68px 0,
      #f3c9cb 69px -69px 0, #f3c9cb 70px -70px 0, #f3c9cb 71px -71px 0,
      #f3c9cb 72px -72px 0, #f3c9cb 73px -73px 0, #f3c9cb 74px -74px 0,
      #f3c9cb 75px -75px 0, #f3c9cb 76px -76px 0;
    border-radius: 55px 90px;
  }
  .video-container .video {
    border-radius: 55px 90px;
    border: 8px solid transparent;
  }
  .banner-heading {
    font-size: 4.5vw;
  }
  .banne-text {
    padding-top: 3.2vw;
  }
  .about-page .what-we-do .wht-we-do-left-img {
    text-align: center;
  }
  .about-page .what-we-do .wht-we-do-left-img img {
    width: 50%;
  }
  .not-found-text {
    position: relative !important;
    transform: translate(-66%, -105%);
  }
}
@media only screen and (max-width: 991px) and (min-width: 481px) {
  .offcanvas-body .container.d-flex.justify-content-between > div {
    width: 50%;
  }
  ul.side-menu-list li a,
  ul.side-menu-list li.title-heading {
    font-size: 16px !important;
  }
  .header-section .offcanvas-body ul li {
    margin-bottom: 10px;
  }
  .header-section .offcanvas-body .left-side-text .left-side-bottom-text {
    text-align: right;
  }
  .header-section .offcanvas-body ul.social-links-list {
    justify-content: flex-end;
  }
}
@media (min-width: 768px) {
  .form-group.col-md-5 {
    width: 46.666667%;
  }
  #sidebar-wrapper {
    margin-left: 0;
  }
  .footer-logo {
    position: relative;
  }
  .footer-logo a {
    position: absolute;
    background: #fff;
    border-bottom: 4px solid #fd4444;
    left: 0;
    padding: 10px 20px 5px;
    bottom: -63px;
    max-width: 210px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 60px 40px -7px;
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .home-banner .banner-buttons {
    margin-top: 37px !important;
  }
}
@media (max-width: 767px) {
  .content-wrapper {
    min-height: calc(100vh - 140px);
  }
  .blog-carousel-item {
    width: 346px !important;
  }
  .about-page .what-we-do .wht-we-do-left-img,
  .abt-dot-img,
  .footer-bottom .footer-bottom-text,
  .footer-social-links p,
  .services-page .capabilities-center {
    display: none;
  }
  .home-blog-section {
    padding-top: 50px !important;
  }
  .blog-section-inner {
    flex-wrap: wrap;
    position: relative;
  }
  .blog-section-inner .blog-left-text .primary-btn {
    position: absolute;
    bottom: -10% !important;
    left: 50% !important;
    transform: translate(-50%, 0);
  }
  .blog-section-inner .blog-right-carousel,
  .business-forward .business-left-text,
  .business-forward .business-right-img,
  .faq .faq-inner .faq-left-img,
  .faq .faq-inner .faq-right-text,
  .footer-social-links,
  .partner-page .expand-earning .expand-left,
  .partner-page .expand-earning .expand-right-text,
  .services-page .our-services .tab-content .tab-left-img,
  .services-page .our-services .tab-content .tab-right-text {
    width: 100% !important;
  }
  #root .home-services-section,
  .abt-our-journey,
  .faq,
  .home-about-section,
  .home-benificial-section,
  .home-feedback-section,
  .home-partner-section,
  .jobs-page .logistic-team,
  .logistics-solutions,
  .make-payments-outer,
  .our-business,
  .partner-page .business-forward,
  .partner-page .expand-earning,
  .py-100,
  .services-counts,
  .services-page .our-capabilities,
  .services-page .our-services,
  .title-banner,
  .what-we-do {
    padding: 50px 0 !important;
  }
  .footer-contact,
  .home-tacking-section {
    padding: 0 !important;
  }
  .become-carrier-page .carrier-main .carrier-text {
    position: relative;
    top: 0;
    left: 0;
    transform: rotate(0);
    background-color: #fff;
  }
  .become-carrier-page .carrier-main .truck-img img {
    width: 95%;
  }
  .about-page .logistics-cards-outer,
  .faq .faq-inner,
  .home-partner-section .partner-main,
  .jobs-page .logistic-team .logistic-team-inner,
  .partner-page .expand-earning .expand-earning-inner,
  .services-page .our-services .tab-content .tab-content-inner {
    display: block !important;
  }
  .home-partner-section .partner-main .partner-left {
    width: 100%;
    margin-bottom: 40px;
  }
  .home-benificial-section .d-flex {
    flex-wrap: wrap;
  }
  .home-benificial-section .justify-content-center {
    justify-content: left !important;
  }
  .benifical-text-left,
  benifical-text-right {
    width: 100% !important;
    text-align: left;
  }
  .navbar ul {
    display: none !important;
  }
  .contact-us-section .contact-left-form,
  .faq .faq-inner .faq-right-text,
  .home-banner .banner-buttons,
  .jobs-page .logistic-team .logistic-left-img,
  .partner-page .expand-earning .expand-right-text,
  .services-page .our-services .tab-content .tab-right-text {
    margin-top: 20px !important;
  }
  .banne-text .primary-btn {
    font-size: 2.2vw !important;
    padding: 1.2vw 4vw !important;
  }
  .banne-text p {
    font-size: 14px;
  }
  .footer-bar {
    flex-wrap: wrap;
    align-items: center;
  }
  .footer-contact,
  .footer-logo {
    width: 50% !important;
    text-align: center !important;
  }
  .footer-contact {
    border: none !important;
  }
  .footer-bar .footer-social-links {
    display: flex !important;
    justify-content: center !important;
    margin-top: 10px;
  }
  .home-tacking-section .container {
    max-width: 100% !important;
    padding: 0;
  }
  .home-tacking-section .tracking-bg-img {
    display: block !important;
    text-align: center;
    border-radius: 0 !important;
  }
  .home-tacking-section .home-tracking-form {
    width: 80% !important;
    margin: 10px auto;
  }
  .home-tacking-section .home-tracking-text {
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
  }
  .home-tacking-section .home-tracking-text h2,
  .home-tacking-section .home-tracking-text p {
    font-size: 28px !important;
    font-weight: 500 !important;
  }
  .home-tacking-section .home-tracking-text p {
    padding-left: 10px;
  }
  .footer-bottom .footer-career,
  .footer-bottom .footer-usefull {
    width: 50% !important;
  }
  .footer-bottom .footer-newsletter {
    width: 100%;
    margin-top: 15px;
  }
  .make-payments-outer .col-md-6 {
    margin-top: 20px !important;
    padding-right: 0 !important;
  }
  .make-payments-outer {
    padding-top: 100px !important;
  }
  .about-page .logistics-cards-outer .logistic-card {
    width: 100% !important;
    max-width: 100%;
    margin-top: 10px;
  }
  .jobs-page .logistic-team .logistic-left-img,
  .jobs-page .logistic-team .logistic-right-img {
    width: 100%;
  }
  .jobs-page .auth-outer .form-row {
    display: block;
  }
  .jobs-page .join-form {
    padding: 50px 20px;
  }
  .jobs-page .join-form .form-group + .form-group {
    margin-top: 20px;
  }
  .partner-page .business-forward .row {
    flex-wrap: wrap-reverse;
    margin: 0 !important;
  }
  .services-count-outer {
    font-size: 12px;
  }
  .services-count-outer .counter {
    font-size: 20px;
  }
  .blog-detail-page {
    padding: 120px 0 80px;
  }
  .blog-detail-page .sidebar .card-body {
    padding-left: 0;
    padding-right: 0;
  }
  .services-page .our-services .nav-item .nav-link {
    padding: 8px 25px;
  }
  .services-page .capabilities-outer .capabilities-list-icon {
    padding: 15px;
    margin: 0 10px 0 0 !important;
    border-radius: 10px 0 10px 10px;
  }
  .our-capabilities .capabilities-right {
    text-align: start;
  }
  .our-capabilities .capabilities-right .d-flex {
    flex-direction: row-reverse;
    justify-content: start;
  }
}
@media (max-width: 730px) {
  .feedback-crousel-outer .react-multi-carousel-list.carousel-container {
    max-width: 540px !important;
    margin: 0 auto !important;
  }
  .feedback-crousel-outer .fb-card-outer {
    width: 100% !important;
    padding: 35px !important;
    background-color: #f5212e;
    color: #ffff;
  }
  .feedback-crousel-outer .quoteImg {
    filter: brightness(100);
  }
  .feedback-crousel-outer .carousel-item-padding {
    padding-bottom: 50px;
  }
}
@media (max-width: 575px) {
  .services-cards-col.me-5 {
    margin-right: 15px !important;
  }
  .services-cards-col .card {
    box-shadow: unset;
  }
  .header-main {
    width: 94% !important;
  }
}
@media (max-width: 480px) {
  .submit-btn {
    width: 100%;
  }
  .header-main .logo {
    margin-left: 15px !important;
  }
  .burger-menu .menu {
    transform: translate(-35%, -50%);
  }
  .footer-contact,
  .footer-logo {
    width: 50% !important;
  }
  .footer-logo img {
    max-width: 120px;
  }
  .footer-contact p {
    word-break: break-word !important;
    text-align: left !important;
  }
  .services-cards-col .card-text {
    font-size: 12px !important;
  }
  .services-cards-col .card {
    border-radius: 15px !important;
  }
  .video-container,
  .video-container .video {
    border-radius: 26px 50px !important;
  }
  .services-cards-col .card .card-title {
    font-size: 14px;
  }
  .services-cards-col .card .card-body {
    padding: 20px 0 0 !important;
  }
  .services-cards-col .card img {
    margin: 0 !important;
    background-color: transparent !important;
    filter: brightness(0) !important;
    padding-bottom: 0 !important;
  }
  body p {
    font-size: 14px !important;
  }
  .home-partner-section .countup-outer .counter {
    font-size: 20px;
    color: #f4212e;
  }
  .benifical-text-right img {
    padding: 5px !important;
    width: 30px;
    border-radius: 0 5px 5px !important;
  }
  .benifical-text-right span.d-flex.align-items-center {
    display: grid !important;
    grid-template-columns: 35px auto;
    gap: 10px;
  }
  .become-carrier-page .carrier-main .carrier-text h1,
  .title-heading {
    font-size: 24px !important;
  }
  .video-container .video {
    border: 3px solid transparent !important;
  }
  .header-section .offcanvas-body .container.d-flex.justify-content-between {
    display: block !important;
  }
  .header-section .offcanvas-body .side-center-buttons {
    display: block !important;
    margin-bottom: 30px;
  }
  .header-section .offcanvas-body .side-center-buttons a {
    color: #fff;
    background-color: #f4212e;
    margin-right: 20px;
    padding: 10px;
    border-radius: 15px;
    font-size: 12px;
  }
  .header-section .offcanvas-body .left-side-text,
  .header-section .offcanvas-body .right-side-menu,
  .payment-contact.py-50.text-center p.w-50.m-auto,
  .stand-out .m-auto.text-center.w-75 {
    width: 100% !important;
  }
  .about-page .what-we-do-list-item .list-text .list-title-heading,
  .header-section .offcanvas-body .title-heading {
    font-size: 16px !important;
  }
  .home-tacking-section .home-tracking-text h2,
  .home-tacking-section .home-tracking-text p {
    font-size: 24px !important;
    font-weight: 500 !important;
  }
  .home-tacking-section .home-tracking-form input::placeholder {
    font-size: 14px;
  }
  .home-tracking-form form .tracking-btn {
    border-radius: 100px;
    font-size: 14px;
    padding: 5px 20px;
  }
  .home-tracking-form form {
    padding: 2px;
    border-radius: 100px;
  }
  .home-tacking-section .home-tracking-form div {
    border-radius: 100px;
    padding-left: 20px;
  }
  .make-payments-outer {
    padding-top: 80px !important;
  }
  .title-banner nav ol {
    font-size: 12px;
    padding: 4px 10px;
  }
  .title-banner .title-banner-main {
    margin-bottom: 10px !important;
  }
  .become-carrier-page .carrier-main .carrier-text .w-50 {
    width: 94% !important;
  }
  .about-page .what-we-do-list-item .list-icon,
  .services-page .capabilities-outer .capabilities-list-icon {
    padding: 5px;
    border-radius: 0 8px 8px;
    width: 40px;
    height: 40px;
  }
  .about-page .what-we-do-list-item .list-icon img,
  .services-page .capabilities-outer .capabilities-list-icon img {
    width: 30px;
  }
  .about-page .what-we-do-list-item .list-text p {
    margin-bottom: 10px;
  }
  .about-page .logistics-solutions .card-icon {
    padding: 30px;
    margin: 0 auto 15px;
  }
  .partners-card {
    padding: 25px 15px 30px;
  }
  .partner-standout-list {
    margin-bottom: 70px;
  }
  .abt-pg-right-img .years-count .counter {
    font-size: 38px;
  }
  .abt-pg-right-img .years-count .years-count-text {
    font-size: 14px;
  }
  .abt-pg-right-img .years-count {
    width: 159px !important;
  }
  .blog-carousel-item {
    width: 427px !important;
  }
}
@media (max-width: 370px) {
  .services-cards-col .card .card-title {
    font-size: 12px !important;
  }
}
